<template>
  <div class="container-fluid mx-auto">
    <div class="my-4 text-center">
      <h2 class="fs-2">TPD Referral Summary</h2>
      <p class="text-gray-600">Select referral to view more</p>
    </div>

    <div
      v-if="pageReady"
      class="d-flex flex-column tpd-list-box"
      @scroll="onScroll"
    >
      <div
        v-for="obj in listToDisplay"
        :key="obj.id"
        class="p-5 my-3 mx-auto referral-list-card rounded-3 shadow cursor-pointer"
      >
        <button
          v-if="$store.state.profile.is_lawyer"
          type="button"
          style="font-size: 0.8em"
          class="btn bg-danger float-end text-white"
          @click="storeDelRef(obj.id)"
        >
          <strong>DELETE</strong>
        </button>
        <TpdCard
          :assessment-data="obj"
          @del-ref="storeDelRef"
          @click="goToReferral(obj)"
        />
      </div>
    </div>

    <div
      v-if="retrievingList"
      class="min-vh-100 d-flex justify-content-center align-items-center"
    >
      <div>
        <div
          class="spinner-border text-primary"
          style="width: 12rem; height: 12rem"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      id="confirmationModal"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="confirmationModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog text-center">
        <div class="modal-content">
          <div class="modal-body py-5">
            <span>Are you sure you want to delete this referral ticket?</span>
            <br />
            <span class="fw-bold">" REFERRAL ID {{ toDelRef }} "</span>
            <p class="mb-0 mt-1">You can't undo this action.</p>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn bg-gray-300 me-4"
              data-bs-dismiss="modal"
              @click="docToRemove = null"
            >
              Cancel
            </button>
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn btn-danger text-white"
              @click="delRef"
            >
              Delete file
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
s
<script>
import TpdCard from '@/components/TPD/Components/TpdCard.vue'
import { Modal } from 'bootstrap'
export default {
  name: 'TpdList',
  components: { TpdCard },
  props: {},

  data() {
    return {
      pageReady: false,
      user: null,
      // Data from api call
      assessmentList: null,
      listToDisplay: [],
      toDelRef: null,
      page: 1,
      retrievingList: false,
      endOfList: false,
    }
  },

  async mounted() {
    if (this.$store.state.profile) {
      this.user = this.$store.state.profile
      await this.getTPDTemplates()
      await this.getTPDList()

      this.pageReady = true
    }
  },

  methods: {
    // async getTPDReferralList() {
    //   let payload = { stringSearch: '?page=1' }

    //   await this.$store
    //     .dispatch('getTPDReferralList', payload)
    //     .then((response) => {
    //       let data = response.data
    //       this.assessmentList = data.results
    //       this.pageReady = true
    //     })
    //     .catch((error) => {
    //       console.log(error)
    //     })
    // },

    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (
        scrollTop + clientHeight >= scrollHeight - 50 &&
        this.assessmentList.length !== 0
      ) {
        this.getTPDList()
      }
    },

    async getTPDTemplates() {
      await this.$store
        .dispatch('getTPDTemplates')
        .then((response) => {
          //store to vuex

          this.$store.state.TPDTemplates = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },

    storeDelRef(id) {
      const myModal = new Modal('#confirmationModal')

      this.toDelRef = id

      return myModal.show()
    },

    async getTPDList() {
      if (!this.retrievingList && !this.endOfList) {
        this.retrievingList = true
        let payload = { page: this.page }

        await this.$store
          .dispatch('getTPDList', payload)
          .then((response) => {
            let data = response.data

            this.assessmentList = data.results

            // Increment page number if it has a next
            if (data.next) this.page += 1
            // Prevent retrieving
            else this.endOfList = true

            // Display initial TPDs
            this.listToDisplay.push(...this.assessmentList)
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.retrievingList = false
          })
      }
    },

    async delRef() {
      let payload = { id: this.toDelRef }

      await this.$store
        .dispatch('delTPDReferral', payload)
        .then(() => {
          window.location.reload()
        })
        .catch((error) => {
          console.log(error)
        })
    },

    goToReferral(obj) {
      if (this.$store.state.profile) {
        let profile = this.$store.state.profile

        //retrieve service type
        //check if specialist is included in this selection
        let specialist_included = obj.referral.service_requirement_choices.find(
          (x) => {
            return (
              x.service_type === obj.referral.service_requirement?.service_type
            )
          }
        ).include_specialist

        let nurse_included = obj.referral.service_requirement_choices.find(
          (x) => {
            return (
              x.service_type === obj.referral.service_requirement?.service_type
            )
          }
        ).include_nurse

        // Page priority lawyer > specialist > doctor > nurse > admin
        let permission = true
        switch (permission) {
          case profile.is_lawyer:
            this.$store.commit('STORE_TPDREFERRAL', obj)
            this.$router.push({
              name: 'tpd-lawyer',
              params: { id: obj.id },
            })
            break
          case specialist_included && profile.is_specialist:
            this.$router.push({
              name: 'tpd-specialist',
              params: { id: obj.id },
            })
            break

          case profile.is_doctor:
            this.$router.push({
              name: 'tpd-doctor',
              params: { id: obj.id },
            })
            break
          case profile.is_nurse && nurse_included:
            this.$router.push({
              name: 'tpd-nurse',
              params: { id: obj.id },
            })
            break
          case profile.is_admin:
            this.$router.push({
              name: 'tpd-admin',
              params: { id: obj.id },
            })
            break
          default:
            alert(
              'The current logged in user has no permission for any TPD Pages'
            )

            this.$router.push({
              name: 'dashboard',
            })
        }

        // // Check for doctor / specialist user
        // if (profile.is_lawyer) {
        //   this.$store.commit('STORE_TPDREFERRAL', obj)
        //   return this.$router.push({
        //     name: 'tpd-lawyer',
        //     params: { id: obj.id },
        //   })
        // }

        // // Check for specialist and if specialist is included in TPD
        // if (specialist_included && profile.is_specialist) {
        //   return this.$router.push({
        //     name: 'tpd-specialist',
        //     params: { id: obj.id },
        //   })
        // }

        // // Check for doctor
        // if (profile.is_doctor) {
        //   return this.$router.push({
        //     name: 'tpd-doctor',
        //     params: { id: obj.id },
        //   })
        // }

        // // Check nurse and if service type allowed nurse
        // if (profile.nurse && nurse_included) {
        //   return this.$router.push({
        //     name: 'tpd-nurse',
        //     params: { id: obj.id },
        //   })
        // }

        // // Check admin
        // if (profile.admin) {
        //   return this.$router.push({
        //     name: 'tpd-admin',
        //     params: { id: obj.id },
        //   })
        // }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.referral-list-card {
  transition: 1s ease all;
  border: 0 solid white;
  max-width: 1500px;
  width: 100%;

  &:hover {
    border: 0.5px solid $cyan;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.tpd-list-box {
  height: 100vh;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
