<template>
  <div style="z-index: -1" class="container">
    <div>
      <p class="text-capitalize fs-5 mb-5">
        Referral #0{{ cardObj.referral_id }} - Patient
        {{ cardObj.patient_name }}
      </p>
    </div>

    <!-- icon wizard row -->
    <div
      class="d-flex flex-row justify-content-between align-items-start wizard-steps"
    >
      <div
        v-if="cardObj.information.referral"
        :id="`${cardObj.referral_id}_referral_section`"
      >
        <div class="mb-4">
          <div
            class="p-3 rounded-circle wizard-icon"
            :class="
              cardObj.information.referral.status === 'completed'
                ? 'icon-completed bg-primary'
                : 'bg-gray-600'
            "
          >
            <img
              src="@/assets/svg/referral-click.svg"
              alt="referral"
              class="w-100 h-100"
            />
          </div>

          <p class="mt-3 fw-light wizard-title">Referral</p>
        </div>
        <div class="fw-light wizard-substage">
          <div class="d-flex align-items-center">
            <div
              class="circle-icon"
              :class="
                cardObj.information.referral.service
                  ? 'circle-success'
                  : 'circle-pending'
              "
            />
            <span>Select service</span>
          </div>

          <div class="d-flex align-items-center">
            <div
              class="circle-icon"
              :class="
                cardObj.information.referral.tpd_format
                  ? 'circle-success'
                  : 'circle-pending'
              "
            />
            <span>Select TPD Format</span>
          </div>
          <div class="d-flex align-items-center">
            <div
              class="circle-icon"
              :class="
                cardObj.information.referral.has_documents
                  ? 'circle-success'
                  : 'circle-pending'
              "
            />
            <span>Upload documents</span>
          </div>

          <div v-if="cardObj.information.referral.created_on">
            <div
              class="circle-icon"
              :class="
                cardObj.information.referral.service
                  ? 'circle-success'
                  : 'circle-pending'
              "
            />
            <span class="d-inline">
              {{ 'Created on ' + cardObj.information.referral.created_on }}
            </span>
          </div>

          <!-- <div>
            <span>{{ cardObj.days_since_referral }} days since referral</span>
          </div> -->
        </div>
      </div>

      <div
        v-if="cardObj.information.admin"
        :id="`${cardObj.referral_id}_admin_section`"
      >
        <div class="mx-auto mb-4">
          <div
            class="p-3 rounded-circle wizard-icon"
            :class="
              cardObj.information.admin.status === 'completed'
                ? 'icon-completed bg-primary'
                : 'bg-gray-600'
            "
          >
            <img
              src="@/assets/svg/calendar.svg"
              alt="admin"
              class="w-100 h-100"
            />
          </div>

          <p class="mt-3 fw-light wizard-title">Admin</p>
        </div>
        <div class="fw-light wizard-substage">
          <div class="d-flex align-items-center">
            <div
              class="circle-icon"
              :class="
                cardObj.information.admin.unable_to_contact_patient
                  ? 'circle-danger'
                  : cardObj.information.admin.all_appointment_booked
                  ? 'circle-success'
                  : 'circle-pending'
              "
            />
            <span>Patient contacted</span>
          </div>

          <div class="d-flex align-items-start">
            <div>
              <span
                class="circle-icon"
                :class="
                  cardObj.information.admin.medical_document_downloaded
                    ? 'circle-success'
                    : 'circle-pending'
                "
              />
            </div>

            <span>Medical documents downloaded</span>
          </div>

          <div>
            <div class="d-flex align-items-center">
              <div
                class="circle-icon"
                :class="
                  checkAllTeleconsults(cardObj.information.admin.teleconsults)
                    ? 'circle-success'
                    : 'circle-pending'
                "
              />
              <span>Booked teleconsults</span>
            </div>
            <div>
              <div
                v-for="(value, key) in cardObj.information.admin.teleconsults"
                :key="key"
                class="d-block mb-1"
              >
                <span>
                  <span class="fw-bold">{{ key }}</span>
                  - {{ value.is_selected ? value.date : 'TBC' }}
                </span>
              </div>
            </div>
          </div>

          <div v-if="cardObj.information.admin.created_on">
            <div
              class="circle-icon"
              :class="
                cardObj.information.admin.service
                  ? 'circle-success'
                  : 'circle-pending'
              "
            />
            <span>Created on {{ cardObj.information.admin.created_on }}</span>
          </div>

          <!-- <div>
            <span>
              {{ cardObj.information.admin.days_since_referral }} days since
              referral
            </span>
          </div> -->
        </div>
      </div>

      <div
        v-if="cardObj.information.nurse"
        :id="`${cardObj.referral_id}_nurse_section`"
      >
        <div class="mx-auto mb-4">
          <div
            class="p-3 rounded-circle wizard-icon"
            :class="
              cardObj.information.nurse.status === 'completed'
                ? 'icon-completed bg-primary'
                : 'bg-gray-600'
            "
          >
            <img class="w-100 h-100" src="@/assets/svg/nurse.svg" alt="nurse" />
          </div>
          <p class="mt-3 fw-light wizard-title">Nurse</p>
        </div>
        <div class="fw-light wizard-substage">
          <div class="d-flex align-items-center">
            <div
              class="circle-icon"
              :class="
                cardObj.information.nurse.teleconsult_completion
                  ? 'circle-success'
                  : 'circle-pending'
              "
            />
            <span>Teleconsult</span>
          </div>

          <div v-if="cardObj.information.nurse.created_on">
            <div
              class="circle-icon"
              :class="
                cardObj.information.nurse.created_on
                  ? 'circle-success'
                  : 'circle-pending'
              "
            />
            <span>Created on {{ cardObj.information.nurse.created_on }}</span>
          </div>

          <div>
            <span>
              {{ cardObj.information.nurse.days_since_referral }} days since
              referral
            </span>
          </div>
        </div>
      </div>

      <div
        v-if="cardObj.information.doctor"
        :id="`${cardObj.referral_id}_tpd_section`"
      >
        <div class="mx-auto mb-4">
          <div
            class="p-3 rounded-circle wizard-icon"
            :class="
              cardObj.information.doctor.status === 'completed'
                ? 'icon-completed bg-primary'
                : 'bg-gray-600'
            "
          >
            <img src="@/assets/svg/tpd.svg" alt="tpd" class="w-100 h-100" />
          </div>
          <p class="fw-light mt-3 wizard-title">Doctor</p>
        </div>
        <div class="fw-light wizard-substage">
          <div class="d-flex align-items-center">
            <div
              class="circle-icon"
              :class="
                cardObj.information.doctor.consultation
                  ?.is_teleconsult_completed
                  ? 'circle-success'
                  : 'circle-pending'
              "
            />
            <span>Doc Teleconsult</span>
          </div>

          <div class="d-flex align-items-center">
            <div
              class="circle-icon"
              :class="
                cardObj.information.doctor.consultation?.is_report_completed
                  ? 'circle-success'
                  : 'circle-pending'
              "
            />
            <span>TPD Report</span>
          </div>

          <div>
            <span>
              {{ cardObj.information.doctor.days_since_referral }} days since
              referral
            </span>
          </div>
        </div>
      </div>

      <div
        v-if="cardObj.information.specialist && include_specialist"
        :id="`${cardObj.referral_id}_tpd_section`"
      >
        <div class="mx-auto mb-4">
          <div
            class="p-3 rounded-circle wizard-icon"
            :class="
              cardObj.information.specialist.status === 'completed'
                ? 'icon-completed bg-primary'
                : 'bg-gray-600'
            "
          >
            <img src="@/assets/svg/tpd.svg" alt="tpd" class="w-100 h-100" />
          </div>
          <p class="fw-light mt-3 wizard-title">Specialist</p>
        </div>
        <div class="fw-light wizard-substage">
          <div class="d-flex align-items-center">
            <div
              class="circle-icon"
              :class="
                cardObj.information.specialist.consultation
                  ?.is_teleconsult_completed
                  ? 'circle-success'
                  : 'circle-pending'
              "
            />
            <span>Specialist Teleconsult</span>
          </div>

          <div class="d-flex align-items-center">
            <div
              class="circle-icon"
              :class="
                cardObj.information.specialist.consultation?.is_report_completed
                  ? 'circle-success'
                  : 'circle-pending'
              "
            />
            <span>TPD Report</span>
          </div>

          <div>
            <span>
              {{ cardObj.information.specialist.days_since_referral }} days
              since referral
            </span>
          </div>
        </div>
      </div>

      <div
        v-if="cardObj.information.ime.status"
        :id="`${cardObj.referral_id}_tpd_section`"
      >
        <div class="mx-auto mb-4">
          <div
            class="p-3 rounded-circle wizard-icon"
            :class="
              cardObj.information.ime.status === 'completed'
                ? 'icon-completed bg-primary'
                : 'bg-gray-600'
            "
          >
            <img src="@/assets/svg/ime.svg" alt="tpd" class="w-100 h-100" />
          </div>
          <p class="mt-3 fw-light wizard-title">IME</p>
        </div>
        <div class="wizard-substage fw-light">
          <div class="d-flex align-items-center">
            <div
              class="circle-icon"
              :class="
                cardObj.information.ime.report_completion_date
                  ? 'circle-success'
                  : 'circle-pending'
              "
            />
            <div>
              <span class="d-block">IME Report</span>
              <span>
                {{
                  cardObj.information.ime.is_referred ? '' : '(Not Referred)'
                }}
              </span>
            </div>
          </div>

          <div v-if="cardObj.information.ime.created_on">
            <div
              class="circle-icon"
              :class="
                cardObj.information.ime.created_on
                  ? 'circle-success'
                  : 'circle-pending'
              "
            />
            <span>Created on {{ cardObj.information.ime.created_on }}</span>
          </div>
        </div>
      </div>

      <!-- completed or not icon -->
      <div :class="invoiceCompletion">
        <div class="mx-auto mb-4">
          <div
            class="p-3 rounded-circle wizard-icon"
            :class="invoiceCompletion"
          >
            <img src="@/assets/svg/sent.svg" alt="sent" class="w-100 h-100" />
          </div>
          <p class="fw-light mt-3 wizard-title">Sent</p>
        </div>
        <div class="wizard-substage fw-light">
          <div class="d-flex align-items-center">
            <div class="circle-icon" :class="invoiceCompletion" />
            <span>
              {{
                cardObj.information.invoice.invoice_completion
                  ? 'Completed'
                  : 'Pending completion'
              }}
            </span>
          </div>

          <div>
            <span>
              {{ cardObj.information.invoice.days_since_referral }} days since
              referral
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'TpdCard',
  components: {},
  props: {
    assessmentData: {
      type: [Object],
      required: true,
    },
  },

  data() {
    return {
      cardObj: {
        referral_id: null,
        patient_name: '',
        date_of_completion: null,
        days_since_completion: 0,
        days_since_created: 0,
        days_since_referral: 0,

        information: {
          referral: {
            status: null,
            days_since_referral: 0,
            created_on: null,
            completed_on: null,
            service: null,
            tpd_format: null,
            has_documents: [],
          },
          admin: {
            status: null,
            days_since_referral: 0,
            created_on: null,
            completed_on: null,
            all_appointment_booked: false,
            unable_to_contact_patient: false,
            medical_document_downloaded: false,
            teleconsults: {
              GP: {
                is_selected: false,
                date: null,
                name: null,
              },
            },
          },
          nurse: {
            status: null,
            days_since_referral: 0,
            created_on: null,
            completed_on: null,
            report_completion_date: null,
            teleconsult_completion: null,
          },
          doctor: {
            status: null,
            days_since_referral: 0,
            completed_on: null,
            consultation: {},
          },
          specialist: {
            status: null,
            days_since_referral: 0,
            completed_on: null,
            consultation: {},
          },
          ime: {
            status: null,
            is_referred: true,
            report_completion_date: null,
            days_since_referral: 0,
            created_on: null,
            completed_on: null,
          },
          invoice: {
            invoice_completion: false,
            days_since_referral: 0,
          },
        },
      },

      include_specialist: null,
    }
  },

  computed: {
    invoiceCompletion() {
      let css_class

      if (
        this.cardObj.information.doctor.status === 'completed' &&
        (this.cardObj.information.specialist?.status === 'completed' ||
          !this.include_specialist) &&
        this.cardObj.information.invoice.invoice_completion &&
        (!this.include_nurse ||
          this.cardObj.information.nurse.status === 'completed') &&
        this.cardObj.information.referral.status === 'completed'
      ) {
        css_class = 'wizard-last-stage-success'
      } else if (
        this.cardObj.information.doctor.status === 'completed' &&
        (this.cardObj.information.specialist?.status === 'completed' ||
          !this.include_specialist)
      ) {
        css_class = 'wizard-last-stage-orange'
      } else css_class = 'wizard-last-stage-gray'

      return css_class
    },
  },

  mounted() {
    this.mapReferralTicket()
  },

  methods: {
    checkAllTeleconsults(obj) {
      let keys = Object.keys(obj)
      let fields = ['date', 'is_selected']
      for (let item in keys) {
        // Get field
        for (let fieldIndex in fields) {
          let teleconsult = obj[keys[item]]
          let field = fields[fieldIndex]
          // Field is not set
          if (!teleconsult[field]) {
            return false
          }
        }
      }
      return true
    },

    formatDate(date, type) {
      if (type === 'time') {
        const result = dayjs(date).format('DD-MM-YYYY , hh:mma')
        return result
      } else if (type !== 'time') {
        const result = dayjs(date).format('DD-MM-YYYY')

        return result
      } else return null
    },

    markAppointment(obj) {
      let prefixRoles = ['doctor', 'nurse', 'specialist']
      let postfixFields = ['', '_appointment', '_appointment_duration']
      if (obj) {
        // Roles to be popped
        let roleCheck = ['specialist', 'nurse']
        for (let roleIndex in roleCheck) {
          // Get role
          let role = roleCheck[roleIndex]
          // Remove roles if not included
          if (!this[`include_${role}`]) {
            prefixRoles.splice(prefixRoles.indexOf('role'), 1)
          }
        }

        // Loop through fields
        for (let roleIndex in prefixRoles) {
          for (let fieldIndex in postfixFields) {
            // Set object key name
            let key = prefixRoles[roleIndex] + postfixFields[fieldIndex]
            // If any appointment isn't set, return false
            if (!obj[key]) return false
          }
        }
        return true
      }
      return false
    },

    mapReferralTicket() {
      //date calculation

      let today = dayjs(new Date())
      let ref_update_date = dayjs(
        this.assessmentData.referral.date_time_updated
      )

      //so long as they aren't done, just show them as today - start of assessment creation date, if it's done, use the date_time_updated - the referral creation date
      this.cardObj.days_since_referral = today.diff(ref_update_date, 'day')
      this.cardObj.days_since_created = today.diff(
        dayjs(this.assessmentData.referral.date_time_created),
        'day'
      )

      //default to date calculation above if incomplete
      Object.keys(this.cardObj.information).forEach(
        (x) =>
          (this.cardObj.information[x].days_since_referral =
            this.cardObj.days_since_created)
      )

      //map service_type
      this.include_specialist =
        this.$store.state.TPDTemplates.service_requirement_choices.find(
          (x) =>
            x.service_type ===
            this.assessmentData.referral.service_requirement.service_type
        ).include_specialist

      //remove specialist if needed
      !this.include_specialist ? delete this.cardObj.information.specialist : ''

      //map service_type
      this.include_nurse =
        this.$store.state.TPDTemplates.service_requirement_choices.find(
          (x) =>
            x.service_type ===
            this.assessmentData.referral.service_requirement.service_type
        ).include_nurse

      //remove nurse if needed
      !this.include_nurse ? delete this.cardObj.information.nurse : ''

      //find name
      this.cardObj.referral_id = this.assessmentData.id
      this.cardObj.patient_name = `${this.assessmentData.referral.patient.first_name} ${this.assessmentData.referral.patient.middle_name} ${this.assessmentData.referral.patient.last_name}`

      //find tpd format name

      let foundTpdFormat =
        this.assessmentData.referral.report_format_requirements

      //temporary do not have completion date system

      //lawyer referral inputs
      let fillRef = this.cardObj.information.referral

      fillRef.created_on = this.formatDate(
        this.assessmentData.referral.date_time_created
      )

      fillRef.service =
        this.assessmentData.referral.service_requirement.service_type

      fillRef.tpd_format =
        foundTpdFormat.length > 0 ||
        this.assessmentData.referral.new_reports.length > 0
          ? true
          : false

      //check all doc category is at least uploaded or marked
      //check for mark as unavailable then check if remaining docs are uploaded

      //check if this referral has any uploaded documents by lawyers
      let uploadedAndMarked = false

      let filteredAry = []

      if (this.assessmentData.referral.unavailable_documents.length > 0) {
        //filter medical related documents
        filteredAry = this.$store.state.document_types.filter(
          (id) =>
            !this.assessmentData.referral.unavailable_documents.includes(id.id)
        )

        // At least have 1 referral document
        if (filteredAry.length > 0) {
          uploadedAndMarked = filteredAry.every(
            (e) => this.assessmentData.referral[e.api_title].length > 0
          )
        }
      }

      let hasLegalDocs =
        this.assessmentData.referral.legal_letters.length > 0 ? true : false
      let hasOtherDocs =
        this.assessmentData.referral.other_docs.length > 0 ? true : false

      //Upload Documents should turn green so long as a file on the Legal briefing document
      //or Medical documents or Other documents have something uploaded into them,
      //and the remaining files are marked as not available in Medical documents

      fillRef.has_documents = uploadedAndMarked || hasLegalDocs || hasOtherDocs

      fillRef.status =
        fillRef.has_documents &&
        this.assessmentData.referral.service_requirement.service_type &&
        fillRef.tpd_format
          ? 'completed'
          : 'disabled'

      //referral checkpoint done

      //admin inputs
      let fillAdm = this.cardObj.information.admin

      // referral last update - sub milestone updated date
      //date calculation

      this.cardObj.days_since_referral = today.diff(ref_update_date, 'day')

      fillAdm.days_since_referral

      //check if admin uploaded medical docs to their own local server
      fillAdm.medical_document_downloaded =
        this.assessmentData.is_created_on_local_system

      if (this.assessmentData.teleconsult_appointment) {
        let teleconsultAppointment = this.assessmentData.teleconsult_appointment
        let fields = ['', '_appointment', '_appointment_duration']
        if (this.include_specialist) {
          let isSelected = true
          for (let fieldIndex in fields) {
            let field = 'specialist' + fields[fieldIndex]
            if (!teleconsultAppointment[field]) {
              isSelected = false
              break
            }
          }

          fillAdm.teleconsults.SP = {
            is_selected: isSelected,
            date: this.formatDate(
              teleconsultAppointment.specialist_appointment,
              'time'
            ),
            name: null,
          }
        }
        if (this.include_nurse) {
          let isSelected = true
          for (let fieldIndex in fields) {
            let field = 'nurse' + fields[fieldIndex]
            if (!teleconsultAppointment[field]) {
              isSelected = false
              break
            }
          }

          fillAdm.teleconsults.RN = {
            is_selected: isSelected,
            date: this.formatDate(
              teleconsultAppointment.nurse_appointment,
              'time'
            ),
            name: null,
          }
        }

        let isSelected = true
        for (let fieldIndex in fields) {
          let field = 'doctor' + fields[fieldIndex]
          if (!teleconsultAppointment[field]) {
            isSelected = false
            break
          }
        }

        fillAdm.teleconsults.GP = {
          is_selected: isSelected,
          date: this.formatDate(
            teleconsultAppointment.doctor_appointment,
            'time'
          ),
          name: null,
        }

        // Check if contacted patient
        fillAdm.unable_to_contact_patient =
          this.assessmentData.teleconsult_appointment.unable_to_contact_patient

        //check if all appointments r booked
        if (this.markAppointment(this.assessmentData.teleconsult_appointment)) {
          fillAdm.all_appointment_booked = true
          fillAdm.status = 'completed'

          //date calculation for admin if completed
          let adm_update = dayjs(
            this.assessmentData.teleconsult_appointment.date_time_updated
          )

          this.cardObj.information.doctor.days_since_referral = adm_update.diff(
            ref_update_date,
            'day'
          )
        }
      } else fillAdm.status = 'disabled'

      //checkpoint for admin

      //check if IME is needed
      this.cardObj.information.ime.status = this.assessmentData.referral
        .service_requirement.ime_report
        ? 'enabled'
        : null

      //below are mappable without specific profile roles

      //mapping nurse...
      if (this.include_nurse && this.assessmentData.patient_detail) {
        //stringify before runnin object compare is a must
        let hasNurseVal = this.assessmentData.patient_detail.is_saved

        this.cardObj.information.nurse.teleconsult_completion = hasNurseVal

        if (hasNurseVal) {
          //date calculaton for nurse if completed

          let nurse_update = dayjs(
            this.assessmentData.patient_detail.date_time_updated ??
              this.assessmentData.patient_detail.date_time_created
          )

          this.cardObj.information.nurse.days_since_referral =
            nurse_update.diff(ref_update_date, 'day')

          this.cardObj.information.nurse.status = 'completed'
        }
      }

      //mapping doctor consult
      if (this.assessmentData.doctor_consultation) {
        this.assessmentData.doctor_consultation.is_report_completed &&
        this.assessmentData.doctor_consultation.is_teleconsult_completed
          ? (this.cardObj.information.doctor.status = 'completed')
          : ''
        this.cardObj.information.doctor.consultation =
          this.assessmentData.doctor_consultation

        if (this.cardObj.information.doctor.status === 'completed') {
          //date calculation for doc if completed
          let doc_update = dayjs(
            this.assessmentData.doctor_consultation.date_time_updated
          )

          this.cardObj.information.doctor.days_since_referral = doc_update.diff(
            ref_update_date,
            'day'
          )
        }
      }

      //mapping doctor consult for specialist
      if (this.include_specialist) {
        this.assessmentData.specialist_consultation?.is_report_completed &&
        this.assessmentData.specialist_consultation?.is_teleconsult_completed
          ? (this.cardObj.information.specialist.status = 'completed')
          : ''
        this.cardObj.information.specialist.consultation =
          this.assessmentData.specialist_consultation

        if (this.cardObj.information.specialist.status === 'completed') {
          //date calculation for doc
          let spec_update = dayjs(
            this.assessmentData.specialist_consultation.date_time_updated
          )

          this.cardObj.information.specialist.days_since_referral =
            spec_update.diff(ref_update_date, 'day')
        }
      }

      //mapping invoice (sent)

      if (
        this.assessmentData.invoice?.invoice_and_report_sent &&
        this.assessmentData.invoice?.invoice_completed
      ) {
        this.cardObj.information.invoice.invoice_completion = true
        //date calculation for invoice if completed
        let inv_update = dayjs(this.assessmentData.invoice.date_time_updated)

        this.cardObj.information.invoice.days_since_referral = inv_update.diff(
          ref_update_date,
          'day'
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wizard {
  &-icon {
    width: 65px;
    height: 65px;
    margin: 0 auto;
    position: relative;

    &::after {
      content: '';
      width: 350%;
      max-width: 13vw;
      height: 10px;
      background-color: #919191;
      border-radius: 10px;
      position: absolute;
      left: 0;
      top: 40%;
      z-index: -1;
    }
  }

  &-substage {
    font-size: 0.875rem;

    padding: 0 10%;

    > div {
      margin-bottom: 0.25rem;
    }
  }

  &-last-stage-gray {
    color: #919191;

    &.circle-icon {
      background-color: #919191;
    }

    &.wizard-icon {
      background-color: #6c757d;

      &::after {
        background-color: #919191;
      }
    }
  }

  &-last-stage-orange {
    color: #db6d13;

    &.wizard-icon,
    &.circle-icon {
      background-color: #fd7e14;
    }

    &.wizard-icon::after {
      background-color: #fd7e14;
    }
  }

  &-last-stage-success {
    color: #32c3d6;
    &.wizard-icon,
    &.circle-icon {
      background-color: #32c3d6;
    }

    &.wizard-icon::after {
      background-color: #32c3d6;
    }
  }

  &-title {
    font-size: 0.875rem;
    line-height: 140%;
    text-align: center;
  }

  &-steps {
    > div {
      width: 16.7%;
    }

    > div {
      &:not(:last-child):not(:first-child) {
        .wizard-icon:before {
          content: '';
          width: 350%;
          max-width: 13vw;
          height: 10px;
          background-color: #919191;
          border-radius: 10px;
          position: absolute;
          right: 0;
          top: 40%;
          z-index: -2;
        }
      }

      &:first-child {
        .wizard-icon::after {
          width: 225%;
          left: 90%;
          top: 40%;
        }
      }

      &:last-child {
        .wizard-icon::after {
          width: 225%;
          max-width: 10vw;
          left: -215%;
          top: 40%;
        }
      }
    }
  }
}

.icon-completed {
  &::after,
  &::before {
    background-color: #32c3d6 !important;
  }
}

.circle {
  &-icon {
    margin-right: 0.3125rem;
    border-radius: 50%;
    min-height: 0.5rem;
    min-width: 0.5rem;

    display: inline-block;
  }
  &-success {
    background-color: #27c92f;
  }

  &-pending {
    background-color: #919191;
  }

  &-orange {
    background-color: #fd7e14;
  }

  &-danger {
    background-color: red;
  }
}
</style>
